export const textTemplatesEnglish = {
  BDS_BREADCRUMBS_BACK_BUTTON_LABEL: 'Back',

  // common
  ADDRESS_ID_LABEL: 'Address ID',
  CONTRACT_ID_LABEL: 'Contract ID',
  DISPATCH_ID_LABEL: 'Dispatch ID',
  DISPOSAL_COVERAGE_LABEL: 'Disposal Coverage',
  PART_REQUEST_ID_LABEL: 'Part Request ID',
  REPLACEMENT_ID_LABEL: 'Replacement ID',
  SERVICE_ITEM_LABEL: 'Service Item',

  COMPANY_PHONE_NUMBER_LABEL: '303-000-0000',
  ERROR_AGENT:
    'Something has gone wrong. Please restart your process with this dispatch',
  ERROR_CUSTOMER:
    'Something has gone wrong. Please try again or call 1-800-326-6710 Option 2',
  ERROR_CUSTOMER_REPLACEMENT_LOCK_NOT_FOUND_ERROR:
    'Replacement is locked due to error, please contact agent for a new replacement offer',
  ERROR_CUSTOMER_REPLACEMENT_NOT_CONFIRMED_ERROR:
    'Replacement needs to be confirmed, please refresh the page and try again',
  ERROR_CUSTOMER_GET_REPLACEMENT_FOR_PURCHASE_ERROR:
    'Cannot fetch the replacement at this point, please try again at later time.',
  ERROR_CUSTOMER_NON_EXISTENT_ID_ERROR:
    'Cannot fetch the replacement at this point, please try again at later time.',
  ERROR_CUSTOMER_INVALID_ID_ERROR:
    'Cannot fetch the replacement at this point, please try again at later time.',
  ERROR_CUSTOMER_PRODUCT_NOT_AVAILABLE: 'Product not available for purchase',
  MAXIMUM_PAYMENT_ATTEMPTS_ERROR:
    "You've reached the maximum number of customer payment attempts. Please contact an agent at 1-800-326-6710 Option 2",

  ERROR_AGREEMENTS: 'All agreements are required',

  BDS_MESSAGE_INFO: 'Info',

  // hsa
  HSA_DISPATCH_TEXT: 'This is an HSA customer',

  // navbar
  NAVBAR_HELLO: 'Hello, {name}',
  NAVBAR_FAQ_LABEL: 'FAQ',
  NAVBAR_LINK_QUEUE: 'Queue',
  NAVBAR_LINK_AGENT_FAQ: 'FAQ',
  NAVBAR_LINK_TRACKING: 'Track Order',
  NAVBAR_LINK_E_CARD: 'E-Card',
  NAVBAR_LINK_PAYMENT: 'Payment',

  // FAQ
  FAQ_HEADER: 'Frequently Asked Questions (FAQ)',
  FAQ_0_TITLE: 'What happens after I place my order?',
  FAQ_0_BODY: `After your order processes through our system and to the vendor providing your new appliance, you will receive an email with your order number and the name of the company that will be installing your new appliance. This email will include the phone number of the installation company in the event that you need to contact them directly. However, the installation company will contact you as soon as they receive your new appliance to schedule an installation time with you.`,
  FAQ_1_TITLE: 'What if I want to order matching items for my new appliance?',
  FAQ_1_BODY: `At this time, this site will only allow you to select a replacement for the item designated. We hope to allow additional purchases at a later date. However, to order your replacement along with a matching item, you can do that by calling {tenant} for help. We can order your replacement as well as obtain discounted pricing on the additional unit as a benefit of being an {tenant} customer.`,
  FAQ_2_TITLE: `Why do some items have a cost associated, I thought {tenant} was paying for my replacement?`,
  FAQ_2_BODY: `{tenant} will pay for a comparable replacement appliance. Those items that you may choose from without any additional cost to you can be found by clicking on the “$0 / At No Cost” tab above the filtering options. Additionally, above certain units the price will show as $0. These units are available for you to select without any out of pocket costs to you. If you would like to upgrade to a different model you may do so by paying the out of pocket cost listed above the appliance at check out.`,
  FAQ_3_TITLE: `I did not receive a confirmation e-mail for my order, did it go through?`,
  FAQ_3_BODY: `The confirmation e-mail will be sent immediately once your order is placed. If you did not receive this e-mail please return to the portal, make your selection, and be sure to check the acknowledgement boxes to continue to place your order.`,
  FAQ_4_LINK: `For more information about your Lowe's e-card, click <a  target='_blank' style='color:#212121; font-weight:bold;text-decoration: underline;' href='https://lowes.cashstar.com/about/faq'>here</a>.`,
  FAQ_CLOSE_BTN: 'Close',
  //agent FAQ
  FAQ_AGENT_DESCRIPTION: `How can we help you?`,
  FAQ_404: `Nothing found, no posts matched your criteria`,
  // product
  DELIVERY_DATE: `Estimate delivery date : {deliveryDate}`,

  FAQ_AGENT_TITLE_0: `When a shopping link is emailed to the homeowner, is it always automatically sent to the email of the person that is listed as "talking to"?`,
  FAQ_AGENT_BODY_0: `No, it is not. Shopping link emails are sent to the email listed as the "buyer".`,
  FAQ_AGENT_TITLE_1: `What if the member doesn't have an email address on file?`,
  FAQ_AGENT_BODY_1: `To send a shopping link with the member's replacement options, an email is required. If the member does not have email, ask for a relative's email to use temporarily and update the “buyer” email field and enter the temporary email address in the Appliance Option Notes in STAR. Once the member receives the shopping link email, remove the temporary email address from the “buyer” email field in STAR to prevent the recipient from receiving future emails.`,
  FAQ_AGENT_TITLE_2: `How long is a shopping link active?`,
  FAQ_AGENT_BODY_2: `The shopping link will expire 21 days from the date the link is emailed to the member.`,
  FAQ_AGENT_TITLE_3: `How do I send a member a shopping link if the appliance on the dispatch has been cancelled?`,
  FAQ_AGENT_BODY_3: `To send a member a shopping link email, the status of all the appliances on the dispatch must be updated from Cancel to Open in STAR.`,
  FAQ_AGENT_TITLE_4: `How can I view the member's shopping page?`,
  FAQ_AGENT_BODY_4: `Open a new tab, copy the appliance portal URL including the member's Replacement ID and the extension shop in this format /replacementID/shop OR go through dispatch lookup as normal and select “Order for Customer” button instead of “Send Replacement Offer”. NOTE: make sure to select the same like-for-like product or the customer's CIL will change.`,
  FAQ_AGENT_TITLE_5: `Why am I getting this error when trying to view the member's shopping page? The customer's shopping page cannot be viewed while logged in as an agent. Please open a new tab in incognito mode (CTRL + SHIFT + N) and paste the customer's URL shopping link to view their shopping page.`,
  FAQ_AGENT_BODY_5: `The error is occurring because you are trying to view the member's shopping page while logged into the appliance portal. A member's shopping page can only be viewed in Incognito mode.`,
  FAQ_AGENT_TITLE_6:
    'How can I help a member who is seeing an error when trying to open their shopping link email?',
  FAQ_AGENT_BODY_6: `The member should try opening the email using a different device or browser.`,
  FAQ_AGENT_TITLE_7: `How can a member request a CIL (cash-in-lieu)?`,
  FAQ_AGENT_BODY_7: `The member will need to use the link in the shopping link email or survey email to access the form to request a cash-in-lieu. Members do not have the ability to request or accept a CIL through the portal.`,
  FAQ_AGENT_TITLE_8: `How is CIL calculated in the portal?`,
  FAQ_AGENT_BODY_8: `The member will need to use the link in the shopping link email or survey email to access the form to request a cash-in-lieu. Members do not have the ability to request or accept a CIL through the portal.`,
  FAQ_AGENT_TITLE_9: `How Long Does It Take For Homeowners to Receive Their CIL?`,
  FAQ_AGENT_BODY_9: `Checks are processed and mailed within 21 business days after the member accepts the CIL offer. Shield Plus/Shield Complete members as well as members in Arizona, California, New Mexico and Washington, will automatically be sent a check within 21 business days of the CIL Offer.`,
  FAQ_AGENT_TITLE_10: `Will the homeowner's system be automatically covered moving forward?`,
  FAQ_AGENT_BODY_10: `No. We require a proof of replacement receipt in order to reinstate coverage. The invoice must meet the following criteria:<br> • Must be legible<br> • Must be from the store you purchased the item from or the contractor who replaced it for you<br> • Must be a new replacement appliance<br> • Please add the new model number of the replacement appliance to the receipt<br> • Must clearly state the item that was purchased`,
  FAQ_AGENT_TITLE_11: `Why am I unable to send a homeowner a shopping link if they already accepted a CIL for another item on the same dispatch?`,
  FAQ_AGENT_BODY_11: `If a homeowner accepts a reimbursement, the shopping link will no longer work for any other appliance on that same dispatch.`,
  FAQ_AGENT_TITLE_12: `When trying to email a homeowner a shopping link, I'm receiving an error indicating EMPTY_CUSTOMER: No Customer Data Is Available. What can I do to fix this?`,
  FAQ_AGENT_BODY_12: `Confirm that the “buyer” email and phone number fields are populated with the homeowner's information.`,
  FAQ_AGENT_TITLE_13: `Why is the portal showing a unit is now unavailable when the homeowner is ready to place the order but was showing it was available when I sent them the shopping link?`,
  FAQ_AGENT_BODY_13: `When initially searching for availability, it shows items that have been cached to save time. To get the most updated availability, you will need to refresh the offer.`,
  FAQ_AGENT_TITLE_14: `Can e-cards be cancelled if the homeowner changes their mind?`,
  FAQ_AGENT_BODY_14: `No. Once an e-card is ordered, it cannot be cancelled.`,
  FAQ_AGENT_TITLE_15: `What is the link to the Sears repair portal?`,
  FAQ_AGENT_BODY_15: `The link to the Sears repair portal is <a class='text-primary-800' href='https://www.serviceorderportal.com/pages/home'>https://www.serviceorderportal.com/pages/home</a>`,
  FAQ_AGENT_TITLE_16: `Why can I not send replacement options to a homeowner on a dispatch that has more than one of the same appliances?`,
  FAQ_AGENT_BODY_16: `You can only send a shopping link email to the first appliance on the dispatch. To do so, you will need to move the replacement option to the first appliance that needs to be replaced.`,
  FAQ_AGENT_TITLE_17: `Why am I unable to send a shopping link when an order has been cancelled? It states it has already been ordered.`,
  FAQ_AGENT_BODY_17: `A new Part Request for the appliance will need to be created.`,

  // make and model search
  MAKE_AND_MODEL_TITLE: 'Make and Model Search',

  // alert banner
  ALERT_NOTIFICATION_TITLE: 'Alert:',
  ALERT_NOTIFICATION_TEXT1:
    'We are currently experiencing system issues with our site and are unable to access product availability. We apologize for the inconvenience.',
  ALERT_NOTIFICATION_TEXT2:
    'Our website is currently undergoing maintenance and products may temporarily be unavailable. We apologize for any inconvenience. Our team is working on enhancements and will have everything up and running as soon as possible.',
  ALERT_NOTIFICATION_TEXT3:
    'Our website is currently undergoing maintenance and GE products may temporarily be unavailable. We apologize for any inconvenience. Our team is working on enhancements and will have everything up and running as soon as possible.',
  ALERT_NOTIFICATION_TEXT4:
    'Our website is currently undergoing maintenance and Whirlpool products may temporarily be unavailable. We apologize for any inconvenience. Our team is working on enhancements and will have everything up and running as soon as possible.',
  ALERT_NOTIFICATION_TEXT5:
    'Our website is temporarily unavailable because our team is working behind the scenes on improvements.',
  ALERT_NO_TEXT: 'Something went wrong, could not get alert message.',

  // agent survey
  SURVEY_WELCOME_AGENT: 'Welcome, {name}',
  SURVEY_DCOV: 'Select if customer has disposal coverage',
  SURVEY_APPLIANCE_OPTIONS_TITLE:
    "Select the customer's appliance options to create a portal link.",
  SURVEY_CUSTOMER_NAME_LABEL: 'Customer Name',
  SURVEY_CUSTOMER_PHONE_LABEL: 'Customer Phone',
  SURVEY_FEATURE_BTN: 'Feature survey',
  SURVEY_MODEL_SEARCH_BTN: 'Model Number Lookup',
  SURVEY_DISPATCH_SEARCH_BTN: 'Search',
  SERVICE_LIMIT_LABEL: 'Service Limit',
  SURVEY_KIT_SIZE_LABEL:
    'Should both agents and customers see Countertop Microwaves with Trim Kits as replacement options?',
  SURVEY_ICEMAKER_ADDON_LABEL: 'Choose icemaker add-on',
  SURVEY_BRAND_LABEL: 'Brand',
  SURVEY_PREVIOUS_BTN: 'Previous',
  SURVEY_SEE_OPTIONS_BTN: 'See options',
  SURVEY_PAYMENT_BTN: 'Payment',
  SURVEY_DISPATCH_SEARCH_EMPTY: 'Please enter a dispatch ID',
  SURVEY_CHANGE_TYPE_BUTTON_LABEL: 'Change Item Type',
  SURVEY_DISPATCH_SEARCH_NOT_FOUND: 'This dispatch was not found',
  SURVEY_DISPATCH_SEARCH_BAD_REQUEST:
    'Please check the dispatch number and try again',
  SURVEY_DISPATCH_SEARCH_EXPIRED:
    "Session expired, you'll be redirected in a few seconds",

  // outright payment
  OUTRIGHT_PAYMENT_HEADER: 'Retail Payment',
  OUTRIGHT_PAYMENT_ORDER_NUMBER_LABEL: 'Order Number',
  OUTRIGHT_PAYMENT_FIRST_NAME_LABEL: 'Customer First Name',
  OUTRIGHT_PAYMENT_LAST_NAME_LABEL: 'Customer Last Name',
  OUTRIGHT_PAYMENT_SKU_LABEL: 'Model/SKU/Service Code',
  OUTRIGHT_PAYMENT_STATE_LABEL: 'Billing State',
  OUTRIGHT_PAYMENT_VENDOR_LABEL: 'Vendor',
  OUTRIGHT_PAYMENT_MODEL_LABEL: 'Model',
  OUTRIGHT_PAYMENT_ORDER_DATE_LABEL: 'Order Date',
  OUTRIGHT_PAYMENT_SKU_LABEL_LABEL: 'SKU',
  OUTRIGHT_PAYMENT_AMOUNT_LABEL: 'Amount',
  OUTRIGHT_PAYMENT_TYPES_LABEL: 'Payment Type',
  OUTRIGHT_PAYMENT_TENANT_ABBREVIATION_LABEL: 'Tenant',
  OUTRIGHT_PAYMENT_SUCCESS: 'Payment processed successfully.',
  OUTRIGHT_PAYMENT_SUCCESS_REDIRECT: 'Go to main page',
  OUTRIGHT_PAYMENT_ERROR:
    'Payment failed. Please check your input data or try a different credit card.',
  OUTRIGHT_PAYMENT_ERROR_REDIRECT: 'Go back to outright payment page',
  OUTRIGHT_PAYMENT_DESCRIPTION_OUTRIGHT_PAYMENT:
    'This payment type should be used to collect payment from homeowners who have an active contract and would like to order an appliance using our appliance discount program OR to collect a payment for a ProConnect HVAC Upgrade.',
  OUTRIGHT_PAYMENT_DESCRIPTION_RESTOCKING_FEE:
    'This payment type should be used to collect payment for restocking fees.',
  OUTRIGHT_PAYMENT_DESCRIPTION_ACCESSORIES:
    'This payment type should be used to collect payment for accessories.',
  OUTRIGHT_PAYMENT_DIALOG_TITLE:
    'The amount is significantly higher than we would expect',
  OUTRIGHT_PAYMENT_DIALOG_TEXT1:
    'Review the restocking fee amount to make sure it is correct.',
  OUTRIGHT_PAYMENT_DIALOG_TEXT2: 'Are you sure you want to continue?',
  OUTRIGHT_PAYMENT_CONFIRM:
    'Are you sure you want to make this quantity change?',
  OUTRIGHT_PAYMENT_CONFIRM_RESET:
    'Are you sure you want to reset shopping cart?',
  OUTRIGHT_PAYMENT_STATUS_ERROR:
    'This replacement isn’t eligible for continuing replacement, only activated, confirmed or created are eligible',

  //Payment search form
  PAYMENT_SEARCH_FORM_ID_LABEL: 'Record ID',
  PAYMENT_SEARCH_FORM_PAYMENT_ID_LABEL: 'Payment ID',
  PAYMENT_SEARCH_FORM_COMPANY_CODE_LABEL: 'Company Code',
  PAYMENT_SEARCH_FORM_VENDOR_LABEL: 'Vendor',
  PAYMENT_SEARCH_FORM_MODEL_NUMBER_LABEL: 'Model Number',
  PAYMENT_SEARCH_FORM_BILLING_STATE_LABEL: 'Billing State',
  PAYMENT_SEARCH_FORM_ORDER_DATE_MIN_LABEL: 'Order Date Min',
  PAYMENT_SEARCH_FORM_ORDER_DATE_MAX_LABEL: 'Order Date Max',
  PAYMENT_SEARCH_FORM_AMOUNT_MIN_LABEL: 'Amount Min',
  PAYMENT_SEARCH_FORM_AMOUNT_MAX_LABEL: 'Amount Max',
  PAYMENT_SEARCH_FORM_ORDER_NUMBER_LABEL: 'Order Number',

  // e-card
  E_CARD_TITLE: 'Lowe’s E-Card Requests',
  E_CARD_SEARCH: 'Select search type',
  E_CARD_REPORT_FORMAT: 'Select E-Card Report Format',
  E_CARD_SEARCH_BY_DATE: 'Select Date For Report To Generate',

  // stripe payment error messages
  STRIPE_PAYMENT_FAILED_GENERIC:
    "Payment failed. Please verify all information is entered correctly or try a different credit card. If the problem persists and you're sure the information is correct, try refreshing your page.",
  STRIPE_PAYMENT_FAILED_INVALID_CVV:
    "Payment failed. Invalid CVV. Please enter a valid CVV code or try a different credit card. If the problem persists and you're sure the information is correct, try refreshing your page.",
  STRIPE_PAYMENT_FAILED_INVALID_CARD:
    "Payment failed. Invalid card number. Please enter a valid card number or try a different credit card. If the problem persists and you're sure the information is correct, try refreshing your page.",
  STRIPE_PAYMENT_MIN_DOLLAR_ERROR:
    'Payment failed. Minimum payment amount is $1. Please enter a valid payment amount and try again.',

  // browse/shop
  CHECKING_AVAILABILITY:
    'We are checking availability, this will only take a moment...',
  BROWSE_BASED_MODEL_LABEL: 'Based on Model {model}',
  BROWSE_OPTIONS_TITLE_CUSTOMER: 'Replacement Options',
  BROWSE_OPTIONS_TITLE_AGENT: 'Options',
  BROWSE_OPTIONS_SUBTITLE_CUSTOMER:
    'All prices are shown as member cost after deducting your appliance value.',
  BROWSE_OPTIONS_SUBTITLE_AGENT:
    'Select a recommended replacement for the customer.',
  BROWSE_MOBILE_FILTERS_TITLE: 'Filters ({count})',
  BROWSE_MOBILE_FILTERS_BACK: 'Back',
  BROWSE_CUSTOMER_NOTIFICATION_TITLE: 'Special Notice:',
  BROWSE_CUSTOMER_NOTIFICATION_SUBTITLE:
    'New Appliance inventories are at all time low levels.',
  BROWSE_CUSTOMER_NOTIFICATION_BODY:
    'The impacts of Covid-19 have created a greater demand and manufacturers have taken steps to protect their production staff. We want you, our customer, to be aware as you shop that specific products may require a backorder or be unavailable. Unfortunately this is unavoidable and will require flexibility in many cases.',
  BROWSE_AGENT_NOTIFICATION:
    'Inventory has already been checked on all items below for the member’s zip code. Use the lowest priced item that fits their like to like features. If no items are displayed try reviewing the survey or adjusting filters below. If nothing appears, offer CIL as the member may find broader selection locally.',
  PRODUCT_CARD_VIEW_DETAILS_BTN: 'View Details',
  PRODUCT_LIKE_FOR_LIKE_LABEL: 'Your feature matched product',
  PRODUCT_PREFERRED: 'Preferred!',
  BROWSE_CIL_BTN: 'Replacement Value',
  BROWSE_CIL_CLOSE_BTN: 'Close',
  BROWSE_CIL_TEXT:
    'The replacements options presented are based on a reimbursement amount of {cil} that has been discounted from all prices displayed',
  BROWSE_CIL_TEXT1:
    'To request a Cash-In-Lieu, follow the link in the offer email you received.',
  BROWSE_CLEAR_FILTER: 'Clear',
  BROWSE_SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  BROWSE_NO_RESULTS: 'No results at this time, please broaden search.',
  BROWSE_MODEL_SEARCH_LABEL: 'Search by model, SKU or ID',
  BROWSE_MODEL_SEARCH_BTN_LABEL: 'Search',
  BROWSE_MODEL_SEARCH_NOT_FOUND: 'Search did not yield any results.',
  BROWSE_MODEL_SEARCH_ERROR:
    'There was an error while searching the products, please try again.',
  BROWSE_LOGGED_AGENT_ERROR:
    'The customer’s shopping page cannot be viewed while logged in as an agent. Please open a new tab in incognito mode (CTRL + SHIFT + N) and paste the customer’s URL shopping link to view their shopping page.',

  // product detail page
  PRODUCT_UNAVAILABLE: 'Temporarily Out of Stock',
  PRODUCT_DETAILS_BACK_LABEL: 'Back',
  PRODUCT_DETAILS_DIMENSIONS: 'Product Dimensions ({unit})',
  PRODUCT_DETAIL_PROCEED_TO_CHECKOUT_BTN: 'Proceed to Checkout',
  PRODUCT_DETAIL_SELECT_RECOMMENDATION_BTN: 'Select Recommendation',
  PRODUCT_DETAIL_SELECT_RECOMMENDATION_BTN_SHORT: 'Select',
  PRODUCT_DETAIL_FEATURES_TITLE: 'Features',
  PRODUCT_DETAIL_PRODUCT_AVAILABILITY_LABEL: 'Product',
  PRODUCT_DETAIL_ACCESSORY_AVAILABILITY_LABEL: 'Accessory',
  PRODUCT_DETAIL_WARNING_TEXT_REFRIGERATORS: 'PLEASE REVIEW YOUR SELECTION. IF THIS UNIT SPECIFIES AN OPTIONAL ICE MAKER THERE MAY BE AN OUT-OF-POCKET COST IF NOT COVERED BY YOUR CONTRACT. CLICK THE CHAT LINK BELOW FOR MORE DETAILS.',
  PRODUCT_DETAIL_WARNING_TEXT_WASHERS: 'IF YOU REQUIRE AN ACCESSORY (Pedestals, Riser, Stacking Kit, etc.), CALL THE APPLIANCE PURCHASING DEPARTMENT AT 800-326-6710 OR CLICK THE CHAT LINK BELOW TO SPEAK WITH A LIVE AGENT.',
  PRODUCT_DETAIL_WARNING_TEXT_MICROWAVES: 'IF YOU REQUIRE AN ACCESSORY (Filler Kit, etc.), CALL THE APPLIANCE PURCHASING DEPARTMENT AT 800-326-6710 OR CLICK THE CHAT LINK BELOW TO SPEAK WITH A LIVE AGENT.',
  GIFT_CARD_DETAIL_FEATURES_TITLE: 'Details',
  GIFT_CARD_DETAIL_FEATURES_DESCRIPTION:
    'You can select a Lowe’s e-card equal to what we would have paid to replace your covered item, plus the cost of delivery and installation. This amount may also include the cost of disposal if that cost is covered by your home warranty. The e-card may only be used at Lowe’s stores or online, at <a  target="_blank" style="color:#2B3CF1; font-weight:bold;text-decoration: underline;" href="https://lowes.com">www.lowes.com</a>. Please note that if you select this option, you may not later select a different option and refunds are not available. You will be responsible for the purchase, delivery, installation and any necessary disposal. Please check you local Lowe’s store or online first for product availability due to current high demand.',
  GIFT_CARD_DETAIL_FEATURES_WARNING:
    'You will receive a separate email within 3 business days with your e-card information. If amount of e-card is over $2,000 you will receive separate emails totaling your replacement value.',

  // agent confirmation
  AGENT_CONFIRMATION_BACK_BTN: 'Back',
  AGENT_CONFIRMATION_GENERIC_SUCCESS_TITLE:
    'Your recommended replacement has been sent to the customer.',
  AGENT_CONFIRMATION_PURCHASE_SUCCESS_TITLE: 'Process completed successfully.',

  AGENT_CONFIRMATION_PRODUCT_ERROR_TITLE:
    'Product failed to load. Please try refreshing the page or clearing the cache if the problem persists.',
  AGENT_CONFIRMATION_ERROR_TITLE:
    'REPLACEMENT_ORDERED: Replacement Has Already Been Ordered',
  AGENT_CONFIRMATION_SUCCESS_SUBTITLE: 'Replacement ID:',
  AGENT_CONFIRMATION_SELECTED_APPLIANCE_TITLE:
    'Selected appliance to send as top option',
  AGENT_DIALOG_CONFIRMATION:
    'A replacement record already exists for this customer for the following item.',
  AGENT_DIALOG_CONFIRMATION_DETAILS:
    'If you continue, selecting a new like-for-like replacement, the CIL value previously provided to the customer will change.',
  AGENT_CONFIRMATION_SEND_BTN: 'Send offer to customer',
  AGENT_CONFIRMATION_ORDER_FOR_CUSTOMER: 'Order for customer',
  AGENT_CONFIRMATION_CREATE_ANOTHER: 'Begin another Dispatch',
  AGENT_CONFIRMATION_RECORD_EXTERNAL_ORDER: 'Record external order',
  AGENT_CONFIRMATION_REPLACEMENT_ERROR:
    'There was an error creating the replacement. Please try again.',
  AGENT_CONFIRMATION_REGEX_ERROR:
    'Value may only contain letters and numbers and be between 6 and 15 characters long',
  AGENT_CONFIRMATION_COMMENTS_ERROR:
    'General comments may only contain up to 500 characters maximum',
  AGENT_CONFIRMATION_MANDATORY_FIELD_ERROR: 'This field is mandatory',
  // checkout page
  CHECKOUT_TITLE: 'Checkout',
  CHECKOUT_PRODUCT_DIMENSIONS_TITLE: 'Product Dimensions ({unit})',
  CHECKOUT_WARRANTY:
    'Cost of replacement, delivery and installation are covered by your warranty.',
  CHECKOUT_WARRANTY_OVER_3K:
    'Products over $3,000.00 exceed contract values, you will be responsible for Installation fees.',
  CHECKOUT_PAYMENT_INFO_TITLE: 'Payment information',
  CHECKOUT_CONTACT_INFO_TITLE: 'Contact information',
  CHECKOUT_WARNING_CONTACT_INFO:
    'Please make sure that your contact info is up to date in case we need to call you about your delivery.',
  CHECKOUT_WARNING_CONTACT_GIFT_CARD_INFO:
    'Please make sure your Email Address is correct as this is where your e-card will be sent. If it is incorrect, please call us at ',
  CHECKOUT_PHONE_LABEL: 'Phone number',
  CHECKOUT_PHONE_CANCEL: 'Cancel',
  CHECKOUT_PHONE_EDIT: 'Edit',
  CHECKOUT_PHONE_CONFIRM: 'Confirm',
  CHECKOUT_ADDRESS_TITLE: 'Property Address',
  CHECKOUT_PHONE_LABEL_ALTERNATE: 'Alternative Phone Number',
  CHECKOUT_NAME_LABEL_ALTERNATE: 'Alternative Name',
  CHECKOUT_AGREEMENTS_TITLE: 'Agreements',
  CHECKOUT_AGREEMENTS_DIMENSIONS_LABEL:
    'The model I have selected measures {measure} {parsedUnit}. I have measured the space where the model will be installed and it is sufficient to accommodate this appliance.',
  CHECKOUT_AGREEMENTS_PRODUCT_DIMENSIONS:
    'The {type} selected measure width {x}, height {y}, depth {z}. I have measured the space where the model will be installed and it is sufficient to accommodate this appliance',
  CHECKOUT_TERMS_AND_CONDITIONS_0: 'I confirm my Email Address is correct.',
  CHECKOUT_TERMS_AND_CONDITIONS_1: 'I have read and agree to the',
  CHECKOUT_TERMS_AND_CONDITIONS_2:
    'By electing the Lowe’s e-card, I agree and accept this as my reimbursement and satisfies my original service request.',
  CHECKOUT_TERMS_AND_CONDITIONS_3:
    'I understand that once the Lowe’s e-card is selected and issued, it cannot be cancelled.',
  CHECKOUT_TERMS_AND_CONDITIONS_4:
    'I understand and accept that if I return my replacement appliance for any reason other than damage, I will be responsible for paying a restocking fee up to 25% of the cost of the appliance. Reasons including, but not limited to the model dimensions not properly fitting the space available for it, product features or style will be subject to this restocking fee.',
  CHECKOUT_TERMS_AND_CONDITIONS_LINK: 'terms and conditions',
  CHECKOUT_TERMS_AND_CONDITION_TRIM_KIT_SIZE:
    'Please note that trim kits are not returnable or refundable. Please make sure this is the correct size you need, or you may be billed for the entire amount of the trim kit if a new one has to be ordered for any reason other than damage.',
  CHECKOUT_TERMS_AND_CONDITION_ICEMAKER:
    'Please note that the icemaker is not returnable or refundable. Please make sure this is the correct size you need, or you may be billed for the entire amount of the icemaker if a new one has to be ordered for any reason other than damage.',
  CHECKOUT_DELIVERY_INSTRUCTIONS_LABEL: 'Delivery Instructions',
  CHECKOUT_SUMMARY_TITLE: 'Order Summary',
  CHECKOUT_YOU_PAY_TEXT: 'You pay',
  CHECKOUT_E_CARD_VALUE: 'e-card Value',
  CHECKOUT_PLACE_ORDER_BTN: 'Place Order',
  CHECKOUT_AGENT_COMMENTS_LABEL: 'General comments: if any',
  CHECKOUT_AGENT_CONFIRMATION_LABEL: 'Order Number',
  CHECKOUT_AGENT_RADIO_NO_CHARGE_LABEL: '$0 cost to customer',
  CHECKOUT_AGENT_RADIO_CHARGE_LABEL: 'Customer charge',
  CHECKOUT_AGENT_ORDER_BUTTON: 'Submit',
  CHECKOUT_AGENT_AMOUNT_LABEL: 'Amount',

  // terms and conditions
  TERMS_HEADER: 'Terms and Conditions',
  TERMS_LINE_1:
    'I have selected the model of my choice and I am satisfied with its features and benefits.',
  TERMS_LINE_2:
    'My {tenant} coverage provides for only the basic installation and I am responsible for any other modifications that may be necessary to install the model (for example, widening a space, removing baseboards or cabinets, extending a gas line, etc.)',
  TERMS_LINE_4:
    'I must inspect the model for damage upon delivery and notify {tenant} immediately if it is damaged. Failure to notify {tenant} of any damage may result in a waiver of my right to return the model.',
  TERMS_LINE_5: 'Once the model is installed, I cannot return it.',
  TERMS_LINE_6:
    'I am responsible for disposal of the old model if disposal services are not included as part of my {tenant} coverage.',
  TERMS_E_CARD_LINE_1:
    'The Lowe’s e-card is equal to what we would have paid to replace your covered item, plus the cost of delivery and installation. This amount may also include the cost of disposal if that cost is covered by your home warranty',
  TERMS_E_CARD_LINE_2:
    'You will be responsible for the purchase, delivery, installation and any necessary disposal.',
  TERMS_E_CARD_LINE_3:
    'Once you place the order for the e-card, it cannot be cancelled or refunded; and you will not be able to select a different reimbursement option.',
  TERMS_E_CARD_LINE_4:
    'You may not use an e-card to purchase other e-cards. E-cards cannot be reloaded, resold, transferred for value, or redeemed for cash, except to the extent required by law.',
  TERMS_CLOSE_BTN: 'Close',

  // confirmation page
  CONFIRMATION_THANK_YOU:
    'Congratulations, your order is being processed and your new {name} will be on its way soon.',
  CONFIRMATION_ORDER_PROCESSING: 'Your order is being processed.',
  CONFIRMATION_YOU_ORDERED: 'You ordered:',
  CONFIRMATION_E_CARD_VALUE: 'value: {cil}',
  CONFIRMATION_DIMENSIONS: 'Product Dimensions ({unit})',
  CONFIRMATION_TOTAL_CHARGED_TITLE: 'Total charged to your card:',
  CONFIRMATION_E_CARD_TITLE: 'Your e-card order was placed successfully',
  CONFIRMATION_E_CARD_DELIVERY:
    'You will receive an email with your e-card information within 3 business days from the following email address: giftcards@cashstar.com.',
  CONFIRMATION_E_CARD_LOWES_SHOPPING:
    "Go Shopping! Visit any Lowe's store or purchase online at ",
  CONFIRMATION_E_CARD_AMOUNT:
    'If amount of e-card is over $2,000 you’ll receive separate emails totaling your replacement value.',
  CONFIRMATION_WHAT_NEXT: 'What happens next:',
  CONFIRMATION_RECEIVE_EMAIL:
    'You will receive an email with your order number and information about the vendor who will complete your installation.',
  CONFIRMATION_INSTALLATION_CONTACT:
    'The installation company will contact you to schedule installation of your new appliance.',
  CONFIRMATION_DELIVERY_SCHEDULE:
    'Typical delivery will be scheduled within 2 – 3 weeks.',
  CONFIRMATION_CONTACT_INFO:
    'Questions? Call us at <a href="tel:8003455535">800-345-5535</a> Monday through Friday between 7 a.m. and 7p.m. CST.',

  // pagination
  BDS_PAGINATION_ITEMS_PER_PAGE: 'Items per page',
  BDS_PAGINATION_OF_ITEMS: ' of {TOTAL_ITEMS} items',
  BDS_BREADCRUMBS_BACK_BUTTON_DEFAULT_LABEL: 'Back',
  BDS_PAGINATION_PREVIOUS_PAGE: 'Previous page',
  BDS_PAGINATION_NEXT_PAGE: 'Next page',
  BDS_PAGINATION_TOTAL_ITEMS_PLACEHOLDER: '{TOTAL_ITEMS}',
  BDS_PAGINATION_PAGE: 'Page',
  BDS_PAGINATION_ITEMS: 'Items',
  BDS_PAGINATION_VIEW_ALL_ITEMS: 'View All Items',

  // queue
  QUEUE_VENDOR_LABEL: 'Vendor',
  QUEUE_ADDRESS_ERROR: 'Address ID has wrong format.',
  QUEUE_REPLACEMENT_ERROR: 'Replacement ID has wrong format.',
  QUEUE_STATUS_LABEL: 'Status',
  QUEUE_TABLE_HEAD_NOTES: 'Notes',
  QUEUE_TABLE_HEAD_ACTION: 'Action',
  QUEUE_TABLE_HEAD_CREATED: 'Creation Date',
  QUEUE_TABLE_HEAD_LAST_UPDATED: 'Last Updated',
  QUEUE_TABLE_HEAD_FIRST_NAME: 'First Name',
  QUEUE_TABLE_HEAD_LAST_NAME: 'Last Name',
  QUEUE_TABLE_HEAD_STATUS: 'Status',
  QUEUE_TABLE_HEAD_MODEL: 'Model Number',
  QUEUE_TABLE_HEAD_PRICE: 'Price',
  QUEUE_TABLE_HEAD_VENDOR: 'Vendor',
  QUEUE_TABLE_HEAD_PAID: 'Amount Paid',
  QUEUE_TABLE_HEAD_VENDOR_ORDER_NUMBER: 'Vendor Order Number',
  QUEUE_TABLE_NOTE_MODAL_HEADER_MESSAGE: 'Message',
  QUEUE_TABLE_NOTE_MODAL_HEADER_DESCRIPTION: 'Description',
  QUEUE_TABLE_NOTE_MODAL_HEADER_CREATOR: 'Creator',
  QUEUE_TABLE_NOTE_MODAL_HEADER_DATE: 'Date',
  QUEUE_TABLE_GO_SHOPPING: 'Go shopping',
  QUEUE_TABLE_ORDER_ITEMS: 'Order items',
  QUEUE_TABLE_RESTOCKING: 'Take restocking fee',
  QUEUE_TABLE_ACCESSORY: 'Accessory purchase',
  QUEUE_TABLE_HEAD_CIL: 'CIL',
  QUEUE_TABLE_CIL_MODAL_COMPONENT: 'CIL Component',
  QUEUE_TABLE_CIL_MODAL_VALUE: 'Value',

  PAYMENT_TABLE_HEAD_ORDER_NUMBER: 'Order Number',
  PAYMENT_TABLE_HEAD_AGENT: 'Agent',
  PAYMENT_TABLE_HEAD_AMOUNT: 'Amount',
  PAYMENT_TABLE_HEAD_STATUS: 'Status',
  PAYMENT_TABLE_HEAD_BILLING_STATE: 'Billing State',
  PAYMENT_TABLE_HEAD_COMPANY_CODE: 'Company Code',
  PAYMENT_TABLE_HEAD_CREATED_DATE: 'Created Date',
  PAYMENT_TABLE_HEAD_ID: 'Record ID',
  PAYMENT_TABLE_HEAD_MODEL_NUMBER: 'Model Number',
  PAYMENT_TABLE_HEAD_ORDER_DATE: 'Order Date',
  PAYMENT_TABLE_HEAD_PAYMENT_ID: 'Payment ID',
  PAYMENT_TABLE_HEAD_PAYMENT_TYPE: 'Payment Type',
  PAYMENT_TABLE_HEAD_VENDOR: 'Vendor',
  PAYMENT_TABLE_HEAD_CART: 'Cart',
  PAYMENT_TABLE_CART_MODAL_COMPONENT: 'Cart Item',
  PAYMENT_TABLE_CART_MODAL_VALUE: 'Value',

  QUEUE_SHOPPING_MODAL_TITLE_1: 'Order Items',
  QUEUE_SHOPPING_MODAL_TITLE_2: 'Order Accessory or Take Restock payment',
  QUEUE_SHOPPING_MODAL_DESCRIPTION_1:
    'Please select an action below. You are able to order items or go shopping on behalf of the member.',
  QUEUE_SHOPPING_MODAL_DESCRIPTION_2:
    ' Please select an action below. You are able to purchase an accessory or take a restocking fee.',

  //Order Status
  TRACKING_LOOKUP_TITLE: 'Track Order',
  TRACKING_VENDOR_LABEL: 'Vendor',
  TRACKING_ORDER_NUMBER_LABEL: 'Order Number',
  TRACKING_PURCHASE_ORDER_LABEL: 'Purchase Order',

  TRACKING_DETAILS_BREADCRUMB_CURRENT: 'Tracking Details',
  TRACKING_DETAILS_BREADCRUMB_CHILD: 'Track Order',
  TRACKING_DETAILS_ORDER_NUMBER_LABEL: 'Order Number',
  TRACKING_DETAILS_ORDER_INFO_LABEL: 'Order Info',
  TRACKING_DETAILS_ITEM_LABEL: 'Item',
  TRACKING_DETAILS_VENDOR_LABEL: 'Vendor',
  TRACKING_DETAILS_DISPATCH_LABEL: 'Dispatch',
  TRACKING_DETAILS_ORDER_DATE_LABEL: 'Order Date',
  TRACKING_DETAILS_DELIVERY_DATE_LABEL: 'Estimated Delivery',
  TRACKING_DETAILS_DELIVERY_DATE_LABEL_DELIVERED: 'Delivered',
  TRACKING_DETAILS_PROGRESS_ORDERED_LABEL: 'Ordered',
  TRACKING_DETAILS_PROGRESS_SHIPPED_LABEL: 'Shipped',
  TRACKING_DETAILS_PROGRESS_DELIVERED_LABEL: 'Delivered',
  TRACKING_DETAILS_ORDER_ERROR:
    'There was an error with your request, please try again later',
  TRACKING_DETAILS_ORDER_NOT_FOUND: 'Your search yielded no results',

  // Filters
  FILTER_ERROR_MESSAGE: "Max can't be lower then Min",

  //Status Dialog
  STATUS_DIALOG_TITLE: 'Statuses descriptions',
  STATUS_NEEDS_INSTALLER_TITLE: 'NEEDS_INSTALLER',
  STATUS_ORDERED_EXTERNAL_UNPAID_TITLE: 'ORDERED_EXTERNAL_UNPAID',
  STATUS_ORDER_ERROR_TITLE: 'ORDER_ERROR',
  STATUS_ORDERED_TITLE: 'ORDERED',
  STATUS_PURCHASED_TITLE: 'PURCHASED',

  // Admin page
  ADMIN_LABEL_CACHE: 'Cache',
  ADMIN_LABEL_KEY: 'Key',
  ADMIN_LABEL_VENDOR: 'Vendor',
  ADMIN_LABEL_ACTION: 'Action',
  ADMIN_LABEL_SKU: 'SKU',
  ADMIN_LABEL_ZIP: 'ZIP',
  ADMIN_LABEL_PRICE: 'Price',
  ADMIN_BUTTON_ADD: 'Add',
  ADMIN_BUTTON_DELETE: 'Delete',
  ADMIN_BUTTON_REFRESH: 'Refresh',
  ADMIN_BUTTON_DOWNLOAD: 'Download',
  ADMIN_BUTTON_BROWSE: 'Browse File',
  ADMIN_BUTTON_REPLACE: 'Replace File',
  ADMIN_BUTTON_UPDATE: 'Update File',
  ADMIN_INFO_TEXT_1:
    'A deleted cache will repopulate as it is used. A refresh will force the repopulation after deleting.',
  ADMIN_INFO_TEXT_2:
    'It is highly recommended that you download the current data, before making any changes.',
  ADMIN_FUNCTIONS_LABEL: 'Admin Functions',
  ADMIN_TITLE: 'Admin',
  ADMIN_TITLE_BANNERS_0: 'Schedule Banner',
  ADMIN_TITLE_BANNERS_1: 'Scheduled Banners',
  ADMIN_TITLE_BANNERS_2: 'Edit Banner',
  ADMIN_LABEL_AUDIENCE: 'Audience',
  ADMIN_LABEL_MESSAGE: 'Message',
  ADMIN_LABEL_SELECT_DATE: 'Begin and End date',
  ADMIN_LABEL_SAVE: 'Save',
  ADMIN_LABEL_TYPE: 'Notification status',
  ADMIN_LABEL_DELETE: 'Delete',
  ADMIN_LABEL_EDIT: 'Edit',
  ADMIN_TITLE_BANNERS_NO_FOUND: "There aren't scheduled banners",
  ADMIN_LABEL_CLOSE: 'close',
  ADMIN_REFRESH_MESSAGE:
    'Remember to refresh vendor catalogs when special prices were modified.',
  ADMIN_REPLACE_WARNING: 'original file before replacing',
  ADMIN_UPDATE_WARNING: 'original file before updating',

  //Error form
  DISPATCH_ID_ERROR:
    'The dispatch ID entered is invalid. Please enter the correct dispatch ID. Dispatch ID should be a number',
  CONTRACT_ID_ERROR:
    'The contract ID entered is invalid. Please enter the correct contract ID. Contract ID should be a number',
  PAYMENT_ID_ERROR:
    'The payment ID entered is invalid. Please enter the correct payment ID. Payment ID consist of 10-32 characters and should be a combination of both numbers and letters.',
  ORDER_ID_ERROR:
    'The order number entered is invalid. Please enter the correct order number. Order number consist of 6-45 characters and should be a combination of both numbers and letters.',
  MODEL_ID_ERROR:
    'The model number entered is invalid. Please enter the correct model number. Model number consist of 3-15 characters and should be a combination of both numbers and letters.',
  DEFAULT_ERROR: 'This field is required.',
  AMOUNT_ERROR:
    'Amount sholud be a number larger than 0 and with maximum of 2 decimals.',
  MIN_DATE_ERROR: 'Minimum date is today',
  RANGE_DATE_ERROR: 'End date must be later than start date',
  AMOUNT_ERROR1: 'Amount should be a number with maximum of 2 decimals.',
  MAX_ERROR: 'Maximum length is 100',
  ONLY_LETTERS_ERROR: 'Only letters are allowed.',
  PART_REQUEST_ERROR: 'Only numbers are allowed',
  UPDATE_ERROR:
    'Updating banners failed please stay on this page and retry until successful. Contact appliance portal devs if the problem persists.',
  UUID_ERROR:
    'The record ID entered is invalid. Please enter the correct record ID. Record ID should be a UUID.', //TODO

  MAX_DATE_TODAY_ERROR: 'Maximum date is today.', //TODO
  MAX_AMOUNT_ERROR: 'Amount Min must be lower or equal than amount Max', //TODO
  TEXT_MESSAGE_MIN_ERROR: 'Minimum length is 6 characters',
  TEXT_MESSAGE_MAX_ERROR: 'Maximum length is 255 characters',

  // Purchase Info

  PAYMENT_WAS_PROCESSED_SUCCESSFULLY: 'Payment was processed successfully',
  REPLACEMENT_OFFER_EMAILED_TO_CUSTOMER:
    'Replacement offer emailed to customer.',
  EXTERNAL_ORDER_WAS_SUCCESSFULLY_RECORDED:
    'External order was successfully recorded',

  // Categories conflict alert
  CATEGORIES_ALERT_1: 'This product is not from selected category.',
  CATEGORIES_ALERT_2: 'You cannot select it. Click Yes to go back.',

  BUTTON_CANCEL: 'Cancel',
  BUTTON_YES: 'Yes',
  // Outright Payment
  PAYMENT_HEADER: 'Payments',
  CONTINUE_REPLACEMENT_DESCRIPTION:
    'This form should be used to continue the customer’s replacement or add-on to existing order and to collect payment. This is not to be used as a retail order.',
  CONTINUE_REPLACEMENT_ADJUST_PRICE:
    'Use this only if portal price and catalog price does not match. Please notify the AP Portal Concerns Teams chat to adjust the price in the portal.',
  BUTTON_CONTINUE: 'Continue',
  BUTTON_OVERRIDE: 'Override',
  BUTTON_ADD_TO_CART: 'Add To Cart',
  BUTTON_ADD_FEE: 'Add Fee',
  BUTTON_ADD_CIL: 'CIL Value',
  BUTTON_RESET: 'Reset',
  PAYMENTS_AVAILABILITY_MESSAGE:
    'At least one appliance in the cart is unavailable. Are you sure you want to accept payment for items which can’t be ordered through the appliance portal? You will need to order externally in vendor portals.',

  // ShoppingCart
  SHOPPING_CART_HEADER_ITEM: 'Item',
  SHOPPING_CART_HEADER_PRICE: 'Price',
  SHOPPING_CART_HEADER_TAX: 'Tax',
  SHOPPING_CART_HEADER_QTY: 'QTY',
  SHOPPING_CART_HEADER_TOTAL: 'Total',
  SHOPPING_CART_BUTTON_ADJUST: 'Adjust Price',
  SHOPPING_CART_BUTTON_REAPPLY: 'Reapply',
  BUTTON_SUBMIT: 'Submit',
  BUTTON_ORDER: 'Order',

  // Labels

  LABEL_SKU: 'SKU',
  LABEL_CATEGORY: 'Category',
  LABEL_SUBCATEGORY: 'Subcategory',
  LABEL_DESCRIPTION: 'Description',
  LABEL_PRICE: 'Price',
  LABEL_TAX: 'Apply Tax',
  LABEL_ADD_TO_CART: 'Add To Cart',
  LABEL_TENANT: 'Tenant',
  LABEL_SERVICE: 'Fee',

  // Override Price Form

  PRICE_ADJUSTED_LABEL: 'Adjusted Price',
  PRICE_REASON_LABEL: 'Reason for Change',
  PRICE_TAX_LABEL: 'Apply Tax',
}

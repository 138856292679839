import { useDecision } from '@optimizely/react-sdk'
import { useEffect } from 'react'
import { ChatType } from 'src/components/appliance-portal-routes'
import { api } from 'src/utils/api'

export const useAmazonChat = (type: ChatType) => {
  const [decision] = useDecision('customer_chat')
  const {
    AMAZON_CHAT_AHS_CLIENT_ID_DEV,
    AMAZON_CHAT_HSA_CLIENT_ID_DEV,
    AMAZON_CHAT_AHS_SNIPPED_ID_DEV,
    AMAZON_CHAT_HSA_SNIPPED_ID_DEV,
    AMAZON_CHAT_AHS_CLIENT_ID_TEST,
    AMAZON_CHAT_HSA_CLIENT_ID_TEST,
    AMAZON_CHAT_AHS_SNIPPED_ID_TEST,
    AMAZON_CHAT_HSA_SNIPPED_ID_TEST,
  } = decision.variables
  const quiqLoadHandler = async () => {
    const chat = window.amazon_connect
    if (typeof chat !== 'function') {
      console.error('Amzon Chat Error: window.amazon_connect is not a function')
    }
  }

  useEffect(() => {
    if (Object.keys(decision.variables).length === 0) return

    const isDevOrTest = api.config.ENV !== 'production'
    const src =
      'https://dend6g4sigg57.cloudfront.net/amazon-connect-chat-interface-client.js'
    let id: any = ''
    let snippedId: any = ''

    if (api.config.ENV === 'development') {
      id =
        api.config.TENANT === 'ahs'
          ? AMAZON_CHAT_AHS_CLIENT_ID_DEV
          : AMAZON_CHAT_HSA_CLIENT_ID_DEV
      snippedId =
        api.config.TENANT === 'ahs'
          ? AMAZON_CHAT_AHS_SNIPPED_ID_DEV
          : AMAZON_CHAT_HSA_SNIPPED_ID_DEV
    }
    if (api.config.ENV === 'test') {
      id =
        api.config.TENANT === 'ahs'
          ? AMAZON_CHAT_AHS_CLIENT_ID_TEST
          : AMAZON_CHAT_HSA_CLIENT_ID_TEST
      snippedId =
        api.config.TENANT === 'ahs'
          ? AMAZON_CHAT_AHS_SNIPPED_ID_TEST
          : AMAZON_CHAT_HSA_SNIPPED_ID_TEST
    }

    if (!isDevOrTest) return
    if (type === 'quiq' || !type) return
    const chatHead = document.createElement('script')

    chatHead.src = src
    chatHead.id = id
    chatHead.async = true
    window.amazon_connect =
      window.amazon_connect ||
      function () {
        ;(window.amazon_connect.ac = window?.amazon_connect?.ac || []).push(
          arguments
        )
      }
    const amazonConnect = window.amazon_connect
    amazonConnect('styles', {
      iconType: 'CHAT',
      openChat: { color: '#ffffff', backgroundColor: '#123456' },
      closeChat: { color: '#ffffff', backgroundColor: '#123456' },
    })
    amazonConnect('snippetId', snippedId)
    amazonConnect('supportedMessagingContentTypes', [
      'text/plain',
      'text/markdown',
      'application/vnd.amazonaws.connect.message.interactive',
      'application/vnd.amazonaws.connect.message.interactive.response',
    ])
    amazonConnect('customStyles', {
      transcript: { customerChatBubbleColor: '#BBBBBB' },
    })
    amazonConnect('customizationObject', {
      header: {
        dropdown: true,
      },
      footer: {
        disabled: false,
      },
    })

    chatHead.addEventListener('load', quiqLoadHandler)
    amazonConnect('contactAttributes', {
      tenant: api.config.TENANT.toUpperCase(),
      originatingSite: 'Appliance Portal',
    })
    document.head.append(chatHead)

    return () => {
      chatHead.removeEventListener('load', quiqLoadHandler)
    }
    //eslint-disable-next-line
  }, [type, decision])
  return null
}

import { Dialog, Input, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import React, { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import {
  AddedShoppingCartItem,
  useShoppingCartContext,
} from 'src/hooks/use-shopping-cart-context'
import { api } from 'src/utils/api'
import NotFoundForm from '../not-found-form/not-found-form'
import { isSKU } from 'src/utils/validation-utils'
import { SingleKeyCardQueryParams } from 'src/utils/shared-types'
import AddFee from '../add-fee/add-fee'
import AddCil from '../add-cil/add-cil'

interface IProps {
  isReplacementInfoLoading: boolean
  error: string
  control: Control<any, any>
  vendor: string
  sku: string
  params: SingleKeyCardQueryParams
  isContinueReplacement?: boolean
  resetField: () => void
}
const SkuSearch: React.FC<IProps> = ({
  isReplacementInfoLoading,
  error,
  control,
  vendor,
  sku,
  params,
  resetField,
  isContinueReplacement = false,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    addItemToShoppingCart,
    shoppingCartData,
    setTax,
    hasCartCredit,
    existInCart,
    tenant,
  } = useShoppingCartContext()
  const [isSKUPending, setSKUpending] = useState<boolean>(false)
  const [isNotFoundModal, setIsNotFoundModal] = useState<boolean>(false)
  const [notFoundSKU, setNotFoundSKU] = useState<string>('')
  const [isAvailable, setIsAvailable] = useState<boolean>(false)

  const getItemBySKU = async (vendor: string, sku: string) => {
    if (/^CIL$/.test(sku || '') && hasCartCredit) {
      return
    }
    setSKUpending(true)
    try {
      const item = await api.getCartProducts(`${vendor}-${sku}`, params)

      item.taxDetail && setTax(item.taxDetail)
      setSKUpending(false)
      if (item && item.cartItems.length > 0) {
        let modalShouldOpen = true
        item.cartItems.forEach((v) => {
          v.isAvailableForZip && setIsAvailable(true)
          if (v.description !== 'NON-CATALOG ITEM') {
            modalShouldOpen = false
            addItemToShoppingCart({
              ...v,
              unitPrice: v.unitPrice || 0,
              isTax: !!v.unitTax,
            } as AddedShoppingCartItem)
          } else {
            if (existInCart(v.sku || '')) {
              modalShouldOpen = false
              addItemToShoppingCart({
                ...v,
                isTax: !!v.unitTax,
              } as AddedShoppingCartItem)
            }
          }
        })
        modalShouldOpen && setNotFoundSKU(sku)
        modalShouldOpen && setIsNotFoundModal(true)
        !modalShouldOpen && resetField()
      } else {
        setNotFoundSKU(sku)
        setIsNotFoundModal(true)
      }
    } catch {
      setSKUpending(false)
      setNotFoundSKU(sku)
      setIsNotFoundModal(true)
    }
  }
  return (
    <>
      <div className="max-w-3xl lg:flex lg:gap-4 items-start">
        <Controller
          name="sku"
          control={control}
          render={({ field }) => (
            <Input
              disabled={
                isContinueReplacement ? isReplacementInfoLoading : !vendor
              }
              id="outright-payment-form-sku"
              error={error}
              value={field.value}
              formField
              formFieldClassName="w-full sm:w-auto md:w-56 mb-6 flex-shrink-0"
              label={localizedText('LABEL_SKU')}
              className="w-full"
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase()
                field.onChange(e)
              }}
              onBlur={field.onBlur}
              onKeyUp={(e) => {
                if (
                  isContinueReplacement
                    ? !isSKU(sku) || shoppingCartData.length === 0
                    : !vendor || !isSKU(sku)
                ) {
                  return
                } else if (e.key === 'Enter' || e.keyCode === 13) {
                  getItemBySKU(vendor, sku)
                }
              }}
            />
          )}
        />
        <div className="mt-6 flex gap-4">
          <Button
            id="sku-card-form-submit"
            label={localizedText('BUTTON_ADD_TO_CART')}
            className="custom-button w-full md:w-auto flex justify-center lg:inline-block h-9"
            disabled={
              isContinueReplacement
                ? !isSKU(sku) || shoppingCartData.length === 0
                : !vendor || !isSKU(sku)
            }
            loading={isSKUPending}
            onClick={() => getItemBySKU(vendor, sku)}
            style={{ lineHeight: 1 }}
          />
          <AddFee
            propTenant={{ id: tenant, value: tenant, label: tenant }}
            vendor={vendor}
          />
          <AddCil
            propTenant={{ id: tenant, value: tenant, label: tenant }}
            vendor={vendor}
          />
        </div>
      </div>
      <Dialog
        id="not-found-dialog"
        open={isNotFoundModal}
        modal={true}
        onClose={() => setIsNotFoundModal(false)}
        className=""
        usePortal={true}
      >
        <NotFoundForm
          onClose={() => setIsNotFoundModal(false)}
          sku={notFoundSKU}
          vendor={vendor}
          isAvailable={isAvailable}
        />
      </Dialog>
    </>
  )
}

export default SkuSearch

import { useEffect, useState } from 'react'
import { ChatType } from 'src/components/appliance-portal-routes'
import { api } from 'src/utils/api'

export const useQuiqChat = (type: ChatType) => {
  const [chatAvailable, setChatAvailable] = useState<boolean>(false)

  const isNotProd = api.config.ENV !== 'production'
  const src = api.config.QUIQ_CHAT_SCRIPT_URL
  const config = {
    contactPoint: api.config.QUIQ_CHAT_CONTACT_POINT,
    invitationBlurb: {
      text: 'Have questions, chat with us!',
    },
  }
  type TConfig = typeof config

  const quiqLoadHandler = async (config: TConfig) => {
    const quiq = window.Quiq
    if (typeof quiq !== 'function') {
      console.error('Quiq Chat Error: window.Quiq is not a function')
    } else {
      const chat = await quiq(config)

      const { available } = await chat.getAgentAvailability()
      setChatAvailable(available)
      // Just for testing purposes.
      isNotProd &&
        !available &&
        console.warn(
          '%cThe chat is currently unavailable. Log in as an agent to the admin panel at https://frontdoor-stage.goquiq.com/app/messaging/conversations to test this. Check the chat rules and operating hours.',
          'color:#182af0;font-size:16px;background:#ffffff'
        )
    }
  }

  const chatLoader = async () => await quiqLoadHandler(config)

  const removeBlurb = () => {
    const blurb = document.querySelector('.InvitationBlurb') as HTMLElement

    if (!blurb) return

    const button = document.createElement('button')
    button.innerText = 'X'
    button.style.width = '20px'
    button.style.height = '20px'
    button.style.left = '-10px'
    button.style.top = '-10px'
    button.style.zIndex = '999999999999999999'
    button.className =
      'shadow-lg border border-black bg-white rounded-full absolute text-black text-sm inline-flex items-center justify-center'
    button.addEventListener('click', (e) => {
      const el = e.target as HTMLElement
      const parent = el.parentElement as HTMLElement
      parent.style.display = 'none'
    })
    blurb.appendChild(button)
  }

  useEffect(() => {
    if (!type || (type === 'amazon' && isNotProd)) return
    const chatHead = document.createElement('script')

    chatHead.src = src
    chatHead.async = true

    chatHead.addEventListener('load', chatLoader)

    document.head.append(chatHead)

    return () => {
      chatHead.removeEventListener('load', chatLoader)
    }
    //eslint-disable-next-line
  }, [type])
  return { chatAvailable, removeBlurb }
}
